import "../packs_common/globalFunctions";

import { FunctionComponent } from "react";
import ReactOnRails from "../reactOnRails";

import { Header } from '../../design-system/design-system/slim/modules/readly-header/readly-header';
import { CountrySelect } from '../../design-system/design-system/slim/modules/country-select/country-select';
import { ReadlyFooter } from '../../design-system/design-system/slim/modules/readly-footer/readly-footer';
import CookieConsentDialog from "../components/CookieConsentDialog";
import Translations from "../components/Translations";
import "../scripts/NavigationTracking";

// Watch for new components added to the DOM
document.addEventListener("DOMContentLoaded", () => {
  ReactOnRails.register({
    "Translations": Translations as FunctionComponent,
    "CookieConsentDialog": CookieConsentDialog as FunctionComponent,
  });
  
  Header.init({
    showCountrySelect: CountrySelect.showCountrySelect
  });
  ReadlyFooter.init({
    showCountrySelect: CountrySelect.showCountrySelect
  });
  CountrySelect.init();

  registerComponents();

  new MutationObserver(() => {
    registerComponents();
  })
  .observe(
    document.body,
    {
      attributes: true,
      childList: true,
      subtree: true
    }
  );
});

(() => {
  const w = window as any;
  w.setTheme = (theme) => {
    const html = document.documentElement;
    html.classList.remove('ds-dark-theme-override', 'ds-light-theme-override', 'ds-system-theme');
    if (theme == "dark" || theme == "light") {
      html.classList.add(`ds-${theme}-theme-override`);
    } else if (theme == "system") {
      html.classList.add(`ds-system-theme`);
    }
  }
})();


// Use code splitting for all components that are only on certain pages
const registerComponents = () => {
  const componentNameAttributeName = 'data-js-component-name';
  const componentInitializedAttributeName = 'data-js-component-initialized';
  // Only get components not yet initialized
  const componentsToRegister = document.querySelectorAll(`[${componentNameAttributeName}]:not([${componentInitializedAttributeName}])`) as unknown as Array<HTMLElement>;

  if (componentsToRegister) {
    for (const componentElement of componentsToRegister) {
      const componentName = componentElement.dataset.jsComponentName;
      const componentProps = (() => {
        const tempProps = componentElement.dataset.jsComponentProps;
        if (!tempProps) return null;
        return JSON.parse(tempProps);
      })();

      // Set the component as initialized before init
      componentElement.setAttribute(componentInitializedAttributeName, 'true')

      if (componentName == 'OfferDisplay') {
        import('../../design-system/design-system/slim/components/offer-display/offer-display').then(({ OfferDisplay }) => {
          OfferDisplay.init();
        });
      }

      if (componentName == 'BreadCrumbs') {
        import('../../design-system/design-system/slim/modules/breadcrumb/breadcrumb').then(({ BreadCrumbs }) => {
          BreadCrumbs.init();
        });
      }

      if (componentName == 'FaqSection') {
        import('../../design-system/design-system/slim/modules/faq-section/faq-section').then(({ FaqSection }) => {
          FaqSection.init();
        });
      }

      if (componentName == 'EditorialCategory') {
        import('../../design-system/design-system/slim/pages/spotlight/editorial-category/editorial-category').then(({ EditorialCategory }) => {
          EditorialCategory.init();
        });
      }

      if (componentName == 'EditorialArticle') {
        import('../../design-system/design-system/slim/pages/spotlight/editorial-article/editorial-article').then(({ EditorialArticle }) => {
          EditorialArticle.init();
        });
      }

      if (componentName == 'BodyWithReadMore') {
        import('../../design-system/design-system/slim/components-developer/body-with-read-more/body-with-read-more').then(({ BodyWithReadMore }) => {
          BodyWithReadMore.init({ containerElement: componentElement });
        });
      }

      if (componentName == 'PublicationHero') {
        import('../../design-system/design-system/slim/modules/publication-hero/publication-hero').then(({ PublicationHero }) => {
          PublicationHero.init(componentProps);
        });
      }

      if (componentName == 'IssueHero') {
        import('../../design-system/design-system/slim/modules/issue-hero/issue-hero').then(({ IssueHero }) => {
          IssueHero.init(componentProps);
        });
      }

      if (componentName == 'SearchBar') {
        import('../../design-system/design-system/slim/components/search-bar/search-bar').then(({ SearchBar }) => {
          SearchBar.init();
        });
      }

      if (componentName == 'BodyWithReadMore') {
        import('../../design-system/design-system/slim/components-developer/body-with-read-more/body-with-read-more').then(({ BodyWithReadMore }) => {
          BodyWithReadMore.init({ containerElement: componentElement });
        });
      }

      if (componentName == 'AboutArticle') {
        import('../../design-system/design-system/slim/components/about-article/about-article').then(({ AboutArticle }) => {
          AboutArticle.init({
            ...componentProps,
            containerElement: componentElement
          });
        });
      }

      if (componentName == 'StickyFooter') {
        import('../../design-system/design-system/slim/modules/sticky-footer/sticky-footer').then(({ StickyFooter }) => {
          StickyFooter.init({
            ...componentProps,
            containerElement: componentElement
          });
        });
      }

      if (componentName == 'SyndicatedArticle') {
        import('../../design-system/design-system/slim/components-developer/syndicated-article/syndicated-article').then(({ SyndicatedArticle }) => {
          SyndicatedArticle.init({
            ...componentProps,
            container_element: componentElement
          });
        });
      }

      if (componentName == 'ArticlePaywall') {
        import('../../design-system/design-system/slim/components/paywall/paywall').then(({Paywall}) => {
          Paywall.init({
            ...componentProps,
            container_element: componentElement,
          });
        });
      }

      if (componentName == 'InputText') {
        import('../../design-system/design-system/slim/components/input-text/input-text').then(({InputText}) => {
          InputText.init({
            ...componentProps,
            container_element: componentElement,
          });
        });
      }

      if (componentName == 'HomepageHero') {
        import('../../design-system/design-system/slim/modules/homepage-components/homepage-hero/homepage-hero').then(({ HomepageHero }) => {
          HomepageHero.init(
            componentProps
          );
        });
      }

      if (componentName == 'ReviewsSection') {
        import('../../design-system/design-system/slim/modules/reviews-section/reviews-section').then(({ ReviewsSection }) => {
          ReviewsSection.init();
        });
      }
      
      if (componentName == 'CoverWall') {
        import('../../design-system/design-system/slim/modules/homepage-components/cover-wall/cover-wall').then(({ CoverWall }) => {
          CoverWall.init(componentProps);
        });
      }

      if (componentName == 'CancelSubscription') {
        import('../../design-system/design-system/slim/modules/cancel-subscription/cancel-subscription').then(({ CancelSubscription }) => {
          CancelSubscription.init({
            ...componentProps,
            container_element: componentElement,
          });
        });
      }
    }
  }
}
